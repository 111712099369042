/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
    // Sprawdź agenta użytkownika i ustaw zmienną globalną platform
    if (window.navigator.userAgent.includes("WhatsApp")) {
      window.platform = "whatsapp";
    } else {
      window.platform = "facebook";
    }
  };